import { Currency, CurrencyAmount, Pair, Token, Trade } from 'maki-ftm-sdk'
import lodash from 'lodash'
import { useEffect, useMemo, useState, useRef, useCallback } from 'react'

import { BASES_TO_CHECK_TRADES_AGAINST, CUSTOM_BASES } from 'config/'
import { PairState, usePairs } from 'data/Reserves'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import { tryParseAmount } from 'state/swap/hooks'
import { useActiveWeb3React } from 'hooks/'
import { useAllTokens } from './Tokens'

export function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): Pair[] {
  const { chainId } = useActiveWeb3React()

  // Base tokens for building intermediary trading routes
  const bases: Token[] = useMemo(() => (chainId ? BASES_TO_CHECK_TRADES_AGAINST[chainId] : []), [chainId])

  // All pairs from base tokens
  const basePairs: [Token, Token][] = useMemo(
    () =>
      lodash.flatMap(bases, (base): [Token, Token][] => bases.map((otherBase) => [base, otherBase])).filter(
        ([t0, t1]) => t0.address !== t1.address
      ),
    [bases]
  )

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            // This filter will remove all the pairs that are not supported by the CUSTOM_BASES settings
            // This option is currently not used on Maki swap
            .filter(([t0, t1]) => {
              if (!chainId) return true
              const customBases = CUSTOM_BASES[chainId]
              if (!customBases) return true

              const customBasesA: Token[] | undefined = customBases[t0.address]
              const customBasesB: Token[] | undefined = customBases[t1.address]

              if (!customBasesA && !customBasesB) return true
              if (customBasesA && !customBasesA.find((base) => t1.equals(base))) return false
              if (customBasesB && !customBasesB.find((base) => t0.equals(base))) return false

              return true
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId]
  )

  const allPairs = usePairs(allPairCombinations)

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {})
      ),
    [allPairs]
  )
}

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(currencyAmountIn?: CurrencyAmount, currencyOut?: Currency): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut)

  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      return (
        Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: 3, maxNumResults: 1 })[0] ?? null
      )
    }
    return null
  }, [allowedPairs, currencyAmountIn, currencyOut])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(currencyIn?: Currency, currencyAmountOut?: CurrencyAmount): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyIn, currencyAmountOut?.currency)

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      return (
        Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: 3, maxNumResults: 1 })[0] ??
        null
      )
    }
    return null
  }, [allowedPairs, currencyIn, currencyAmountOut])
}

/**
 * 
 * @param currency token out
 * @returns Returns the best trades for all tokens in to the given token out
 */
export function useTrades(currency: Currency){
  const trades = useRef<Map<string, Trade>>(new Map<string, Trade>())
  const [tokens] = useState(Object.values(useAllTokens()))
  const tokenIndexRef = useRef<number>(0)
  const tokenIn = tokens?.[tokenIndexRef.current];
  const amount = useMemo( () => tryParseAmount('1', tokenIn), [tokenIn])
  const trade = useTradeExactIn(amount, currency)
  if(trade?.inputAmount && !trades.current.has(trade.inputAmount.currency?.symbol)){
    trades.current.set(trade?.inputAmount.currency?.symbol, trade);
  }
  useEffect(() => {
    tokenIndexRef.current += tokenIndexRef.current +1 < tokens.length ? 1 : 0;
  }, [trades.current.size, tokens.length])
  return Array.from(trades.current.values())
}

export function useAllPairs(currencies: Currency[], currency: Currency): Pair[] {
  // const [pairs, setPairs] = useState<Map<string, Pair>>(new Map<string, Pair>())
  const pairsRef = useRef<Map<string, Pair>>(new Map<string, Pair>())
  const currencyIndexRef = useRef<number>(0)
  const currencyRef = useRef<Currency>(currencies?.[currencyIndexRef.current])
  const commonPairs = useAllCommonPairs(currencyRef.current, currency)
  // console.log('currencyIndexRef', currencyIndexRef.current)
  // console.log('commonPairs', commonPairs)

  useEffect(() => {
    const filtereds = commonPairs
    ?.filter( c => !pairsRef.current.has(`${c.token0.address}/${c.token1.address}`));
    if(filtereds?.length){
      // console.log('filtereds', filtereds)
      filtereds.forEach( p => pairsRef.current.set(`${p.token0.address}/${p.token1.address}`, p));
      // filtereds.forEach( p => pairs.set(`${p.token0.address}/${p.token1.address}`, p));
      // currencyIndexRef.current += currencyIndexRef.current +1 < currencies.length ? 1 : 0;
      return
    }
    currencyIndexRef.current += currencyIndexRef.current +1 < currencies?.length ? 1 : 0
  }, [commonPairs, currencies])

  useEffect(() => {
    currencyIndexRef.current += currencyIndexRef.current +1 < currencies?.length ? 1 : 0;
  }, [pairsRef.current.size, currencies])
  return Array.from(pairsRef.current.values())
}
