
import BigNumber from 'bignumber.js/bignumber'
import { ChainId, JSBI, Percent, Token, WFTM } from 'maki-ftm-sdk'


// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}


BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

// CHAIN ID
export const BASE_CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);


// WALLET KEYS
export const BASE_FORTMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
export const BASE_PORTIS_KEY = process.env.REACT_APP_PORTIS_ID


// URLS
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE2_URL = process.env.REACT_APP_BASE2_URL
export const BASE_API_PROFILE_URL = process.env.REACT_APP_API_PROFILE

export const BASE_EXCHANGE_URL = BASE_URL
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/pool`


// BIGNUMBERS
export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)


// GAS
export const DEFAULT_GAS_LIMIT = 200000
export const DEFAULT_GAS_PRICE = 5
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)


// BLOCKS
export const BLOCKS_PER_YEAR = new BigNumber(10512000)
export const MAKI_PER_BLOCK = new BigNumber(16)
export const MAKI_PER_YEAR = MAKI_PER_BLOCK.times(BLOCKS_PER_YEAR)
export const HECO_BLOCK_TIME = 3

export const MAKI_POOL_PID = 0
export const MAKI_FTM_POOL_PID = 1
export const MAKI_USD_POOL_PID = 2
export const USD_FTM_POOL_PID = 3
// export const LAYER_FTM_POOL_PID = 4


// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// // used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// // if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// // for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// // used to ensure the user doesn't send so much HT so they end up with <.01
export const MIN_FTM: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 FTM
// export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))



export const FUSD_MAIN = new Token(ChainId.MAINNET, '0xad84341756bf337f5a0164515b1f6f993d194e1f', 18, 'fUSD', 'Fantom USD');
export const USDC_MAIN = new Token(ChainId.MAINNET, '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', 18, 'USDC', 'USDC');
export const FUSDT_MAIN = new Token(ChainId.MAINNET, '0x049d68029688eAbF473097a2fC38ef61633A3C7A', 18, 'fUSDT', 'Frapped USDT');

export const FUSD_TEST = new Token(ChainId.TESTNET, '0x5E26180D079e7bA66b73BC103799CdbCfDD0D89f', 18, 'fUSD', 'Fantom USD')



const WFTM_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WFTM[ChainId.MAINNET]],
  [ChainId.TESTNET]: [WFTM[ChainId.TESTNET]]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WFTM_ONLY,
  [ChainId.MAINNET]: [...WFTM_ONLY[ChainId.MAINNET], FUSD_MAIN, USDC_MAIN, FUSDT_MAIN],
  [ChainId.TESTNET]: [...WFTM_ONLY[ChainId.TESTNET], FUSD_TEST],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WFTM_ONLY,
  [ChainId.MAINNET]: [...WFTM_ONLY[ChainId.MAINNET], FUSD_MAIN, USDC_MAIN, FUSDT_MAIN],
  [ChainId.TESTNET]: [...WFTM_ONLY[ChainId.TESTNET], FUSD_TEST],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
 export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
  [ChainId.TESTNET]: {},
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      // new Token(ChainId.TESTNET, '0xeeb45d492c7c87d739045fa76feebe1657a05dd5', 18, 'MAKI', 'MakiSwap'),
      // new Token(ChainId.TESTNET, '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18, 'WFTM', 'Wrapped Fantom'),
      new Token(ChainId.MAINNET, '0xEDf5e2Ac09002094Cc51B597CebE58C70182ADC5', 18, 'MAKI', 'MakiSwap'),
      new Token(ChainId.MAINNET, '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18, 'WFTM', 'Wrapped Fantom'),
    ],
  ],
  [ChainId.TESTNET]: [
    [
      // new Token(ChainId.TESTNET, '0xeeb45d492c7c87d739045fa76feebe1657a05dd5', 18, 'MAKI', 'MakiSwap'),
      // new Token(ChainId.TESTNET, '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18, 'WFTM', 'Wrapped Fantom'),
      new Token(ChainId.TESTNET, '0x593e40F3aca5C579A5F13E0d6e8F113E3A6D56b1', 18, 'MAKI', 'MakiSwap'),
      new Token(ChainId.TESTNET, '0xf1277d1ed8ad466beddf92ef448a132661956621', 18, 'WFTM', 'Wrapped Fantom'),
    ],
  ],
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WFTM_ONLY,
  [ChainId.MAINNET]: [...WFTM_ONLY[ChainId.MAINNET], FUSD_MAIN, USDC_MAIN, FUSDT_MAIN],
  [ChainId.TESTNET]: [...WFTM_ONLY[ChainId.TESTNET], FUSD_TEST],
}



// exports
export * from './contracts'
export * from './network'
export * from './urls'
export { default as farmsConfig } from './constants/farms'
export { default as poolsConfig } from './constants/pools'

