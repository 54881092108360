const tokens = {

  // OK
  wftm: {
    name: 'Wrapped Fantom',
    symbol: 'FTM',
    address: {
      250: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
      4002: '0xf1277d1ed8ad466beddf92ef448a132661956621' 
    },
    decimals: 18,
  },

  // OK
  weth: {
    name: 'Wrapped Ethereum',
    symbol: 'ETH',
    address: {
      250: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
      4002: '',
    },
    decimals: 18,
    projectLink: '',
  },

  // OK
  maki: {
    name: 'MakiSwap',
    symbol: 'MAKI',
    address: {
      250: '0xEDf5e2Ac09002094Cc51B597CebE58C70182ADC5',
      4002: '0x593e40F3aca5C579A5F13E0d6e8F113E3A6D56b1',
    },
    decimals: 18,
    projectLink: 'https://makiswap.com/',
  },
  
  // OK
  hmaki: {
    name: 'Heco MakiSwap',
    symbol: 'hMAKI',
    address: {
      250: '0xFa0cA21dA6A94623d953034f140372613F102346',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://makiswap.com/',
  },

  // OK
  fusd: {
    name: 'Fantom USD',
    symbol: 'FUSD',
    address: {
      250: '0xad84341756bf337f5a0164515b1f6f993d194e1f',
      4002: '0x5E26180D079e7bA66b73BC103799CdbCfDD0D89f'
    },
    decimals: 18,
    projectLink: '',
  },

  // OK
  usdc: {
    name: 'USD Coin',
    symbol: 'USDC',
    address: {
      250: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
      4002: '',
    },
    decimals: 6,
    projectLink: '',
  },

  // OK
  fusdt: {
    name: 'Frapped USDT',
    symbol: 'fUSDT',
    address: {
      250: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
      4002: '',
    },
    decimals: 6,
    projectLink: '',
  }, 

  // OK
  soy: {
    name: 'SoyBar Token',
    symbol: 'SOY',
    address: {
      250: '0x07752dfa1aF06063dCc6c986B717c71e9E06a827',
      4002: '',
    },
    decimals: 18,
    projectLink: '',
  },
  
  // OK
  btc: {
    name: 'Bitcoin',
    symbol: 'BTC',
    address: {
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
      4002: '',
    },
    decimals: 8,
    projectLink: '',
  },

}

export default tokens
