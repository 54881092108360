import tokens from './tokens'
import { FarmConfig } from '../types'

const farms: FarmConfig[] = [

  {
    pid: 0,
    lpSymbol: 'MAKI',
    lpAddresses: {
      250: '0xEDf5e2Ac09002094Cc51B597CebE58C70182ADC5',
      4002: '0x593e40F3aca5C579A5F13E0d6e8F113E3A6D56b1',
    },
    token: tokens.maki,
    quoteToken: tokens.wftm,
  },

  {
    pid: 1,
    lpSymbol: 'MAKI-FTM',
    lpAddresses: {
      250: '0xa116caC38D60e2c9AD00DD8E7EC40DD866126Da5',
      4002: '0x9C644DF46788B780FcD61c520F1D5Dc7E0f23D37',
    },
    token: tokens.maki,
    quoteToken: tokens.wftm,
  },
  
  // {
  //   pid: 4,
  //   lpSymbol: 'hMAKI-FTM',
  //   lpAddresses: {
  //     250: '0x587b73e8d6de867Da92d58b4dFCD5Cb5aa07d3d4',
  //     4002: '',
  //   },
  //   token: tokens.hmaki,
  //   quoteToken: tokens.wftm,
  // },

  {
    pid: 2,
    lpSymbol: 'MAKI-FUSD',
    lpAddresses: {
      250: '0x71bf70C9d7C3777A6755e336dF6DB7c27d76A3F6',
      4002: '0xBfe2dD64310d416a180DDCb042885A2C94093aF6',
    },
    token: tokens.maki,
    quoteToken: tokens.fusd,
  },

  {
    pid: 5,
    lpSymbol: 'MAKI-USDC',
    lpAddresses: {
      250: '0x0a83766b18418dddb5f6c35da553e8234e51cf8a',
      4002: '',
    },
    token: tokens.usdc,
    quoteToken: tokens.maki,
  },

  {
    pid: 8,
    lpSymbol: 'MAKI-SOY',
    lpAddresses: {
      250: '0x4917641f66F11901ad1dE348Df9e2C4595DD02AC',
      4002: '',
    },
    token: tokens.soy,
    quoteToken: tokens.maki,
  },

  {
    pid: 9,
    lpSymbol: 'MAKI-fUSDT',
    lpAddresses: {
      250: '0x60cd11A858c764281eFa8d4915C749D31e644c2C',
      4002: '',
    },
    token: tokens.fusdt,
    quoteToken: tokens.maki,
  },

  {
    pid: 3,
    lpSymbol: 'FUSD-FTM',
    lpAddresses: {
      250: '0xe473e990f6265faAf1F913986fe09Bac1c51c80B',
      4002: '0x7Ae26011B4C59b751AAd87338F12403Aa1bfb0B3',
    },
    token: tokens.fusd,
    quoteToken: tokens.wftm,
  },

  {
    pid: 7,
    lpSymbol: 'FTM-USDC',
    lpAddresses: {
      250: '0x48ea9cf8da4ff4287bc6bbf7f5a609f0d28d7773',
      4002: '',
    },
    token: tokens.usdc,
    quoteToken: tokens.wftm,
  },

  {
    pid: 11,
    lpSymbol: 'FTM-wETH',
    lpAddresses: {
      250: '0x6a37d8deD5820Ac27F51A17c1F72DB136b9cECCf',
      4002: '',
    },
    token: tokens.weth,
    quoteToken: tokens.wftm,
  },

  {
    pid: 6,
    lpSymbol: 'MAKI-wETH',
    lpAddresses: {
      250: '0x24f569e53a557332ea1012eba2d1e48a58431e3c',
      4002: '',
    },
    token: tokens.weth,
    quoteToken: tokens.maki,
  },

  {
    pid: 10,
    lpSymbol: 'BTC-wETH',
    lpAddresses: {
      250: '0x6c7960A20FE5D1E2B5e3571158411F80B7F03043',
      4002: '',
    },
    token: tokens.btc,
    quoteToken: tokens.weth,
  },

  // {
  //   pid: 12,
  //   lpSymbol: 'BTC-FUSD',
  //   lpAddresses: {
  //     250: '0x151c9bf5efa1837ac2e8bf5d2b1f33d9e1a0bb74',
  //     4002: '',
  //   },
  //   token: tokens.btc,
  //   quoteToken: tokens.fusd,
  // },

]

export default farms
