import tokens from './tokens'
import { FarmConfig } from '../types'

const priceHelperLps: FarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absense of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: 2,
    lpSymbol: 'MAKI-FUSD',
    lpAddresses: {
      250: '0x71bf70C9d7C3777A6755e336dF6DB7c27d76A3F6',
      4002: '0xBfe2dD64310d416a180DDCb042885A2C94093aF6',
    },
    token: tokens.maki,
    quoteToken: tokens.fusd,
  },
]

export default priceHelperLps
