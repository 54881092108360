import tokens from './tokens'
import { PoolConfig, PoolCategory } from '../types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.maki,
    earningToken: tokens.maki,
    contractAddress: { // MASTERCHEF
        // 256: '0x734A1e360E4C57591CE67F008F1F53304CaC7BAB',
        // 128 : '0x4cb4c9C8cC67B171Ce86eB947cf558AFDBcAB17E',
        250: '0x06b2040383B04d99536f85cb2d49a26Ca26bA6Dd',
        4002: '0x336C2eF388BF1E07F90c55f9377Aeb44734Cd569'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '4',
    sortOrder: 1,
    isFinished: false,
  },
]

export default pools